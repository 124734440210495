import React from 'react';
import { Container, FileListContainer } from '../Upload/style';
import { FieldTitle } from '../../style';
import { StyledLink, StyledLinkItem } from './style';
import { LinkIcon } from '../../../../../assets/svgs/LinkIcon';
import { getFileUrl } from '../../../../../requests/files';

const LinkItem = ({ link, index }: any) => {
  const id = `link-${index}`;
  const isExternalUrl = !link.startsWith('form/');
  const onClick = async () => {
    if (isExternalUrl) return;
    try {
      const {
        data: { url },
      } = await getFileUrl(link);
      window?.open(url, '_blank');
    } catch (err: any) {
      // ToastAlert.ERROR(err?.response?.data?.message);
    }
  };
  return (
    <StyledLinkItem key={`key${id}`}>
      <LinkIcon />
      {!isExternalUrl ? (
        <StyledLink id={id} as="div" onClick={onClick}>
          {link}
        </StyledLink>
      ) : (
        <StyledLink id={id} href={`//${link}`} rel="noopener noreferrer" target="_blank">
          {link}
        </StyledLink>
      )}
    </StyledLinkItem>
  );
};

const LinkList = ({ links }: any) => (
  <FileListContainer>
    {links.map((link: any, index: number) => (
      <LinkItem link={link} index={index} />
    ))}
  </FileListContainer>
);

const Download = ({ title, files }: any) => {
  const links = files ? files.map((file: any) => file.link) : [];

  return (
    <Container>
      <FieldTitle>
        {title.split('\\n').map((line: string, index: number, self: string[]) =>
          index === self.length - 1 ? (
            line
          ) : (
            <>
              {line} <br />
            </>
          )
        )}
      </FieldTitle>
      <LinkList links={links} />
    </Container>
  );
};

export default Download;
