import React, { useState } from 'react';
import { DeleteIconWrapper, FieldTitle } from '../../style';
import UploadInput from '../../../../UploadInput';
import { Container, FileItemInfos, FileListContainer, FileName, FileSize, StyledFileItem } from './style';
import ArchiveIcon from '../../../../../assets/svgs/ArchiveIcon';
import { DeleteIcon } from '../../../../../assets/svgs/DeleteIcon';
import formatBytes from '../../../../../utils/formatBytes';

const FileItem = ({ file, index, deleteFile }: any) => (
  <StyledFileItem>
    <FileItemInfos>
      <ArchiveIcon />
      <div>
        <FileName>{file?.name}</FileName>
        <FileSize>{formatBytes(file?.size)}</FileSize>
      </div>
    </FileItemInfos>
    <DeleteIconWrapper onClick={() => deleteFile(index)}>
      <DeleteIcon />
    </DeleteIconWrapper>
  </StyledFileItem>
);

const FileList = ({ previewFiles, deleteFile }: any) => (
  <FileListContainer>
    {previewFiles.map((file: any, index: number) => (
      <FileItem file={file} index={index} deleteFile={deleteFile} />
    ))}
  </FileListContainer>
);

const Upload = ({ title, supportedFileFormats, ...props }: any) => {
  const [previewFiles, setPreviewFiles] = useState<any[]>([]);

  const acceptFileFormats = supportedFileFormats?.replace(/;/g, ',');

  const deleteFile = (index: number) => {
    setPreviewFiles((prevState) => prevState.splice(index, 1));
  };

  const uploadHandler = (event: any) => {
    const file = event.target.files[0];

    if (!file) return;

    let filename = file.name;

    const previewFile = {
      name: filename,
      size: file.size,
    };

    const nameIsLongerThanTwelveCharacters = filename.length >= 12;

    if (nameIsLongerThanTwelveCharacters) {
      const [name, extension] = filename.split('.');
      filename = `${name.substring(0, 12)}... .${extension}`;
      previewFile.name = filename;
    }

    setPreviewFiles([...previewFiles, previewFile]);
  };

  return (
    <Container>
      <FieldTitle>
        {title.split('\\n').map((line: string, index: number, self: string[]) =>
          index === self.length - 1 ? (
            line
          ) : (
            <>
              {line} <br />
            </>
          )
        )}
      </FieldTitle>
      <UploadInput acceptFileFormats={acceptFileFormats} onChange={uploadHandler} {...props} />
      {!!previewFiles.length && <FileList previewFiles={previewFiles} deleteFile={deleteFile} />}
    </Container>
  );
};

export default Upload;
