import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../../config/i18n/config';

import ReplyFormContext from '../../../../context';
import { AnswerContainer, FieldTitle } from '../../style';
import { InputWrapper } from '../../../../../Form/components/QuestionForm/style';
import FormError from '../../../../../../components/FormError';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerWrapper } from './style';
import { useJwtContext } from '../../../../../../JwtContext';

registerLocale('pt', ptBR);

interface MaskedInputProps {
  onChange: (value: any) => void;
  value: any;
  disabled: boolean;
  dateFormat?: string;
}

const CInputDate = ({ disabled, onChange, value, dateFormat = 'dd/MM/yyyy' }: MaskedInputProps) => {
  const handleChange = (date: Date) => {
    onChange(date);
  };

  const locale = i18n.resolvedLanguage;

  const getSelected = () => {
    if (!value) return null;
    if (typeof value === 'string') return new Date(value);
    return value;
  };
  const { t } = useTranslation();

  return (
    <DatePickerWrapper disabled={disabled}>
      <DatePicker
        disabled={disabled}
        onChange={handleChange}
        selected={getSelected()}
        dateFormat={dateFormat}
        locale={locale}
        placeholderText={t('select.select')}
        disabledKeyboardNavigation
      />
    </DatePickerWrapper>
  );
};

const InputDate = ({ control, errors, accessFieldsName, register, wId, title }: any) => {
  const { formDisabled } = useContext(ReplyFormContext);
  const { t } = useTranslation();

  const decodedJwt = useJwtContext();
  const dateFormat = decodedJwt?.dateFormat;

  return (
    <AnswerContainer>
      <FieldTitle>
        {title.split('\\n').map((line: string, index: number, self: string[]) =>
          index === self.length - 1 ? (
            line
          ) : (
            <>
              {line} <br />
            </>
          )
        )}
      </FieldTitle>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <InputWrapper>
        <Controller
          name={`${accessFieldsName}.answer`}
          control={control}
          rules={{
            required: t<string>('common.required'),
          }}
          render={({ field }) => <CInputDate {...field} dateFormat={dateFormat} disabled={formDisabled} />}
        />
        <FormError errors={errors} name={`${accessFieldsName}.answer`} />
      </InputWrapper>
    </AnswerContainer>
  );
};

export default InputDate;
