import React, { useContext, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { DeleteIconWrapper, FieldTitle } from '../../style';
import { Container, FileItemInfos, FileListContainer, FileName, FileSize, StyledFileItem } from './style';
import formatBytes from '../../../../../../utils/formatBytes';
import ArchiveIcon from '../../../../../../assets/svgs/ArchiveIcon';
import { DeleteIcon } from '../../../../../../assets/svgs/DeleteIcon';
import UploadInput from '../../../../../../components/UploadInput';
import { getFileUrl, uploadFile } from '../../../../../../requests/files';
import FormError from '../../../../../../components/FormError';
import ReplyFormContext from '../../../../context';
import ToastAlert from '../../../../../../components/Toast/toast';

const DownloadDiv = styled.div`
  cursor: pointer;
`;

const FileItem = ({ file, deleteFile }: any) => (
  <StyledFileItem>
    <FileItemInfos>
      <ArchiveIcon />
      <DownloadDiv
        onClick={async () => {
          try {
            const {
              data: { url },
            } = await getFileUrl(file.key);
            window?.open(url, '_blank');
          } catch (err: any) {
            ToastAlert.ERROR(err?.response?.data?.message);
          }
        }}
      >
        <FileName>{file?.name}</FileName>
        <FileSize>{file?.size ? formatBytes(file?.size) : ''}</FileSize>
      </DownloadDiv>
    </FileItemInfos>
    <DeleteIconWrapper onClick={() => deleteFile(file?.key)}>
      <DeleteIcon />
    </DeleteIconWrapper>
  </StyledFileItem>
);

const FileList = ({ previewFiles, deleteFile }: any) => (
  <FileListContainer>
    {previewFiles.map((file: any, index: number) => (
      <FileItem file={file} index={index} deleteFile={deleteFile} />
    ))}
  </FileListContainer>
);

const Upload = ({ errors, title, register, wId, control, accessFieldsName, supportedFileFormats, ...props }: any) => {
  const { formDisabled, watch } = useContext(ReplyFormContext);

  const [previewFiles, setPreviewFiles] = useState<any[]>([]);
  const previewFileRef = useRef<any[]>([]);
  const keys = useRef<any[]>([]);
  const { t } = useTranslation();
  const { id } = useParams<any>();

  const {
    field: { onChange: setValueForInput },
  } = useController({
    name: `${accessFieldsName}.answer`,
    control,
    rules: { required: t<string>('common.required') },
  });

  const acceptFileFormats = supportedFileFormats?.replace(/;/g, ',');

  const deleteFile = (key: string) => {
    previewFileRef.current = previewFileRef.current.filter((prevFile) => prevFile.key !== key);
    setPreviewFiles(previewFileRef.current);

    const filteredKeys = keys.current.filter((prevKey: string) => prevKey !== key);

    keys.current = filteredKeys;
    setValueForInput(filteredKeys);
  };

  const setFileOnState = (file: any) => {
    const previewFile = { ...file };
    const nameIsLongerThanTwelveCharacters = previewFile.name.length >= 12;

    if (nameIsLongerThanTwelveCharacters) {
      const [name, extension] = previewFile.name.split('.');
      previewFile.name = `${name.substring(0, 12)}... .${extension}`;
    }

    previewFileRef.current = [...previewFileRef.current, previewFile];
    setPreviewFiles(previewFileRef.current);
    keys.current = [...keys.current, previewFile.key];
    setValueForInput([...keys.current, previewFile.key]);
  };

  const currentFiles = watch(`${accessFieldsName}.answer`);
  useEffect(() => {
    currentFiles?.forEach?.((key: any) => {
      if (keys.current.includes(key)) return;
      const previewFile = {
        name: key?.split('/').pop(),
        key,
      };
      setFileOnState(previewFile);
    });
  }, []);

  const uploadHandler = async (event: any) => {
    const { files } = event.target;

    if (!files) return;

    [...files].forEach((file: File) => {
      const fileData = new FormData();
      fileData.append('file', file);
      fileData.append('formId', id as string);

      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        },
      };

      try {
        uploadFile(fileData, options).then(({ data }: { data: { key: string } }) => {
          const { key } = data;
          const filename = file.name;

          const previewFile = {
            name: filename,
            size: file.size,
            key,
          };

          setFileOnState(previewFile);
        });
      } catch (e) {
        console.error(e);
      }
    });
  };

  return (
    <Container>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <FieldTitle>
        {title.split('\\n').map((line: string, index: number, self: string[]) =>
          index === self.length - 1 ? (
            line
          ) : (
            <>
              {line} <br />
            </>
          )
        )}
      </FieldTitle>
      <UploadInput
        id={`${accessFieldsName}.answer`}
        acceptFileFormats={acceptFileFormats}
        onChange={uploadHandler}
        disabled={formDisabled}
        {...props}
      />
      <FormError errors={errors} name={`${accessFieldsName}.answer`} />
      {!!previewFiles.length && <FileList previewFiles={previewFiles} deleteFile={deleteFile} />}
    </Container>
  );
};

export default Upload;
