import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from '../../../style';
import { ChoicesContainer } from '../style';
import FormError from '../../../../../../../components/FormError';
import Select from '../../../../../../../components/Select';
import ReplyFormContext from '../../../../../context';

const AnswerSelect = ({ control, register, errors, accessFieldsName, wId, title, choices }: any) => {
  const { formDisabled } = useContext(ReplyFormContext);
  const { t } = useTranslation();

  return (
    <ChoicesContainer>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <FieldTitle>
        {title.split('\\n').map((line: string, index: number, self: string[]) =>
          index === self.length - 1 ? (
            line
          ) : (
            <>
              {line} <br />
            </>
          )
        )}
      </FieldTitle>
      {choices && (
        <Controller
          control={control}
          rules={{
            required: t<string>('common.required'),
          }}
          name={`${accessFieldsName}.answer`}
          render={({ field: { onChange, value } }) => (
            <Select
              isDisabled={formDisabled}
              isClearable={false}
              onChange={(option: { value: string; label: string }) => {
                onChange(option.value);
                return option;
              }}
              value={{
                label: value,
                value,
              }}
              options={choices.map((item: any) => ({
                value: item?.title,
                label: item.title,
              }))}
            />
          )}
        />
      )}
      <FormError errors={errors} name={`${accessFieldsName}.answer`} />
    </ChoicesContainer>
  );
};

export default AnswerSelect;
