import React from 'react';
import Input from '../../../../Input';
import { FieldTitle } from '../../style';

const InputField = ({ type, title, ...props }: any) => (
  <>
    <FieldTitle>
      {title.split('\\n').map((line: string, index: number, self: string[]) =>
        index === self.length - 1 ? (
          line
        ) : (
          <>
            {line} <br />
          </>
        )
      )}
    </FieldTitle>
    <Input type={type} {...props} />
  </>
);

export default InputField;
