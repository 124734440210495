import React from 'react';
import { FieldTitle } from '../../../style';
import Checkbox from '../../../../../MarkableButton/Checkbox';
import { ChoicesContainer, StyledChoice } from '../style';
import { ChoiceFieldProps, ChoiceProps } from '../types';

const Choice = ({ title, index }: ChoiceProps) => (
  <StyledChoice>
    <Checkbox id={`Checkbox${title}${index}`} />
    <span>{title}</span>
  </StyledChoice>
);

const MultipleChoice = ({ title, choices }: ChoiceFieldProps) => (
  <ChoicesContainer>
    <FieldTitle>
      {title.split('\\n').map((line: string, index: number, self: string[]) =>
        index === self.length - 1 ? (
          line
        ) : (
          <>
            {line} <br />
          </>
        )
      )}
    </FieldTitle>
    {choices.map(({ title: choiceTitle }, index: number) => (
      <Choice title={choiceTitle} index={index} />
    ))}
  </ChoicesContainer>
);

export default MultipleChoice;
