import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnswerContainer, FieldTitle } from '../../style';
import Input from '../../../../../../components/Input';
import { InputWrapper } from '../../../../../Form/components/QuestionForm/style';
import FormError from '../../../../../../components/FormError';
import ReplyFormContext from '../../../../context';

const InputField = ({ errors, wId, register, type, title, control, accessFieldsName }: any) => {
  const { formDisabled } = useContext(ReplyFormContext);
  const { t } = useTranslation();

  return (
    <AnswerContainer>
      <FieldTitle>
        {title.split('\\n').map((line: string, index: number, self: string[]) =>
          index === self.length - 1 ? (
            line
          ) : (
            <>
              {line} <br />
            </>
          )
        )}
      </FieldTitle>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <InputWrapper>
        <Controller
          name={`${accessFieldsName}.answer`}
          control={control}
          rules={{
            required: t<string>('common.required'),
          }}
          render={({ field }) => <Input {...field} type={type} disabled={formDisabled} />}
        />
        <FormError errors={errors} name={`${accessFieldsName}.answer`} />
      </InputWrapper>
    </AnswerContainer>
  );
};

export default InputField;
